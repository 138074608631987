.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: var(--light-gray-light);
}
.hljs,
.hljs-subst {
  color: #444;
}

.hljs-attribute,
.hljs-meta-keyword,
.hljs-selector-tag,
.hljs-doctag,
.hljs-name {
  font-weight: bold;
}
.hljs-type,
.hljs-selector-id,
.hljs-selector-class,
.hljs-quote,
.hljs-template-tag,
.hljs-deletion {
  color: #880000;
}

.hljs-section {
  color: #880000;
  font-weight: bold;
}
.hljs-regexp,
.hljs-symbol,
.hljs-variable,
.hljs-template-variable,
.hljs-link,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #bc6060;
}

.hljs-bullet,
.hljs-code,
.hljs-addition {
  color: #397300;
}
.hljs-meta {
  color: #1f7199;
}
.hljs-meta-string {
  color: #4d99bf;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-strong {
  font-weight: bold;
}

/* Custom */

.hljs-string {
  color: var(--grass-dark);
}

.hljs-built_in,
.hljs-keyword {
  font-style: italic;
  color: var(--lavander-dark);
}

.hljs-number {
  color: var(--bittersweet-light);
}

.hljs-title,
.hljs-literal {
  color: var(--blue-jeans-light);
}

.hljs-comment {
  color: var(--medium-gray-dark);
}
